import { Menu, Transition } from '@headlessui/react';
import Link from 'next/link';
import { CgMenu } from 'react-icons/cg';
import { HiChevronRight } from 'react-icons/hi';
import { useWindowSize } from 'usehooks-ts';

import Text from '@/tayara-kit/Text';

const MENU_ITEMS = [
  {
    text: 'Contactez-nous',
    link: '/contact/',
    anchorProps: {
      target: '',
    },
  },
  {
    text: 'Professionnels',
    link: { pathname: '/leads/tayara-pro/' },
    anchorProps: {
      target: '',
    },
  },
  {
    text: 'Offres et services',
    link: '/services',
    anchorProps: {
      target: '',
    },
  },
  {
    text: "Conditions d'utilisation",
    link: { pathname: '/terms/' },
    anchorProps: {
      target: '_blank',
    },
  },
  {
    text: 'Politiques relatives aux cookies',
    link: {
      pathname: '/terms/',
      hash: '#Utilisation des cookies et autres technologies',
    },
    anchorProps: {
      target: '_blank',
    },
  },
];
/**
 * Navbar side menu popup
 */
export const NavbarMenu = () => {
  const windowSize = useWindowSize();

  return (
    <Menu>
      <div className="relative hidden md:block">
        <Menu.Button
          className="py-1 px-3 flex items-center gap-x-2 z-[9999] bg-transparent rounded-xl border-[1px] border-neutral-300/75"
          aria-label="Menu"
        >
          <CgMenu size={18} className="text-white mt-[1.5px]" />
          {windowSize.width > 1275 && (
            <Text
              className=" text-center text-white text-xs font-semibold xl:block"
              text="Aide et renseignements ?"
            />
          )}
        </Menu.Button>

        <Transition
          className={'absolute z-[9999] top-12 lg:left-0'}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Menu.Items
            // {...props}
            // tabIndex={0}
            className={`w-64  rounded-lg p-2 shadow bg-white text-primary-content`}
          >
            {MENU_ITEMS.map((item) => (
              <Menu.Item key={item.text}>
                <Link
                  passHref
                  href={item.link}
                  {...(item.anchorProps || {})}
                  className="w-full p-2 hover:no-underline cursor-pointer border-none flex justify-between text-gray-700 text-sm rounded-xl border-[1px] border-neutral-300 hover:bg-neutral-100 hover:border-neutral-200"
                >
                  <span>{item.text}</span>
                  <span>
                    <HiChevronRight size={20} />
                  </span>
                </Link>
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </div>
    </Menu>
  );
};
