const FrequentlySearchedKeywordsStrip = () => {
  const keywords = [
    'Ordinateur portable 16 RAM',
    'BMW',
    'vélo',
    'mazda tout options',
    'PS5 neuf',
    'samsung occasion',
    'iphone pas cher',
    'maison a louer ain zaghouen',
    'emploi',
    'MacBook Pro 16 pouces M1 Pro chip',
  ];

  return (
    <div className="pointer-events-auto space-y-2">
      <span className="text-sm font-semibold text-neutral-400">
        Les mots clés les plus recherchés sur Tayara
      </span>
      <div className="flex flex-wrap gap-2.5">
        {keywords.map((keyword, index) => (
          <button
            key={index}
            className="badge bg-neutral-100 text-neutral-500 !border !border-neutral-300 !text-xs !p-3 hover:bg-neutral-200"
          >
            {keyword}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FrequentlySearchedKeywordsStrip;
