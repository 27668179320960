import React, { Dispatch, FormEventHandler, useEffect } from 'react';

import { SetStateAction } from 'jotai';
import { useRouter } from 'next/router';
import { FaSearch } from 'react-icons/fa';
import { HiArrowNarrowRight } from 'react-icons/hi';

import ShowToRight from '../animations/ShowToRight';
import { useElasticSearchSearchInput } from '../hooks/useElasticSearchServer';
import useFocus from '../hooks/useFocus';

export interface SearchInputProps
  extends React.HTMLAttributes<HTMLInputElement> {
  /**
   * Apply navbar input styles
   */
  navbar?: boolean;
  /**
   * Apply filter input styles
   */
  filter?: boolean;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  setSearchFocused?: Dispatch<SetStateAction<boolean>>;
  setSearchHasValue?: Dispatch<SetStateAction<boolean>>;
  iconClasses?: string;
}

/**
 * Header that will exist in every page where search functionality is a major need e.g. home page, listing page...
 * not user profile page though
 */
export const SearchInput: React.FC<SearchInputProps> = ({
  navbar,
  filter,
  className,
  setSearchFocused,
  setSearchHasValue,
  iconClasses,
  ...props
}) => {
  // const [
  //   searchQuery,
  //   setSearchQuery,
  //   initSearchWithQuery,
  //   handleSearchInputOnBlur,
  // ] = useMeilisearchSearchInput();

  const [
    searchQuery,
    setSearchQuery,
    initSearchWithQuery,
    handleSearchInputOnBlur,
  ] = useElasticSearchSearchInput();

  const [hasValue, setHasValue] = React.useState(false);

  const handleSearchQuery = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
    setHasValue(event.target.value !== '');
  };

  const [searchInputRef, { hasFocus, ref: inputRef }] =
    useFocus<HTMLInputElement>();

  useEffect(() => {
    if (setSearchFocused) setSearchFocused(hasFocus);
    if (setSearchHasValue) setSearchHasValue(hasValue);
  }, [hasFocus, setSearchFocused, hasValue, setSearchHasValue]);

  // If input has a value but onChange was not emitted
  useEffect(() => {
    if (inputRef && inputRef.value !== '') {
      setHasValue(true);
    }
  }, [inputRef, inputRef?.value, setHasValue]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    if (inputRef) inputRef.blur();
    if (initSearchWithQuery) initSearchWithQuery(e);
  };
  const router = useRouter();
  const isImmoNeuf =
    router.pathname
      .split('/')
      .some((segment) => segment.toLowerCase() === 'immoneuf') ||
    router?.query?.category === 'ImmoNeuf';

  return (
    <div
      className={`relative w-full md:max-w-4xl transition-width duration-300  ${
        hasFocus ? 'w-full' : ''
      }`}
    >
      <form
        onSubmit={handleSubmit}
        className={`
        ${hasFocus ? 'max-w-full' : 'w-full'} relative`}
      >
        <input
          ref={searchInputRef}
          {...props}
          className={`
            ${filter && 'pl-10 pb-[1px] lg:pl-12'}
            ${navbar && 'placeholder:text-neutral-600 '}
            text-sm lg:text-sm cursor-text input-md flex outline-offset-0 outline-1 
            placeholder:text-neutral-500/80 hover:outline-neutral-400
            focus:outline-offset-0 font-extrabold font-arabic transition-width duration-300
            focus:bg-neutral-600 pl-10 !outline-none placeholder:text-neutral-500 w-full
            focus:placeholder:text-neutral-100 rounded-3xl h-8  focus:text-neutral-100 pr-3
            ${
              hasValue
                ? 'pr-20 bg-neutral-600 text-neutral-600'
                : 'bg-neutral-200 text-neutral-600'
            } 
            ${className}`}
          placeholder="Rechercher sur tayara"
          value={searchQuery}
          onChange={handleSearchQuery}
          onBlur={handleSearchInputOnBlur}
        />
        {/* Layer on top of the search input */}
        <span
          className={`absolute inset-0 max-w-full flex items-center justify-between pointer-events-none ${
            navbar || filter ? 'pl-3' : 'px-5'
          }`}
        >
          {/* West side */}
          <span
            className={`${
              navbar
                ? ''
                : filter && (isImmoNeuf ? 'rounded-full ' : 'rounded-full  ')
            }
            

            
            `}
          >
            <FaSearch
              strokeWidth={2}
              className={`            ${
                isImmoNeuf ? '!text-neuf-100 ' : '!text-primary '
              } ${
                hasFocus || hasValue ? '!text-neutral-500' : '!text-neutral-500'
              } ${iconClasses}`}
              size={16}
            />
          </span>

          {/* East side */}
          <span className="relative pl-0">
            {/* Go/search button */}
            <ShowToRight isShowing={hasValue}>
              {navbar || filter ? (
                <button
                  className={`block rounded-l-none rounded-md px-4 h-9 capitalize pointer-events-auto text-neutral-100 space-x-1 bg-transparent ${iconClasses}`}
                >
                  <HiArrowNarrowRight className="text-white" size={20} />
                </button>
              ) : (
                <button className="flex items-center justify-center rounded-full px-4 h-9 pointer-events-auto text-white space-x-1 bg-primary/80 -mr-3 hover:bg-primary">
                  <HiArrowNarrowRight />
                </button>
              )}
            </ShowToRight>
          </span>
        </span>
      </form>
    </div>
  );
};
