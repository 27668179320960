import { UrlObject } from 'url';

import { Dispatch, useEffect, useMemo, useRef, useState } from 'react';

import { SetStateAction, useAtom } from 'jotai';
import { flatMap } from 'lodash-es';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { IconType } from 'react-icons';
import { AiOutlineHome } from 'react-icons/ai';
import { BiStore } from 'react-icons/bi';
import { CgMenu, CgClose } from 'react-icons/cg';
import { FaPercentage } from 'react-icons/fa';
import {
  HiSparkles,
  HiOutlineUserCircle,
  HiOutlineSparkles,
  HiOutlineShieldCheck,
  HiOutlineCollection,
} from 'react-icons/hi';
import {
  IoPricetag,
  IoLogOut,
  IoPricetagOutline,
  IoHelpCircleOutline,
} from 'react-icons/io5';
import {
  MdOutlineNotificationsActive,
  MdOutlineRealEstateAgent,
} from 'react-icons/md';
import { RiDashboard2Line } from 'react-icons/ri';
import { useBoolean, useReadLocalStorage, useWindowSize } from 'usehooks-ts';

import { LoadHeavyContentAtom } from '@/lib/store';
import useLoginHook from '@/modules/auth/hooks/useLoginHook';
import { UserType } from '@/modules/profile/types';
import MobileStickyBanner from '@/tayara-kit/banners/MobileStickyBanner';
import Divider, { Orientation } from '@/tayara-kit/Divider';
import { TkTokenHistory } from '@/tayara-kit/icons';
import Text from '@/tayara-kit/Text';

import { NavbarMenu } from './NavbarMenu';
import { SearchInput } from './SearchInput';
import { useElasticSearchKeyCategories } from '../hooks/useElasticSearchServer';
import useProfile from '../hooks/useProfileHook';

interface IprofileSubMenu {
  name: string;
  label: string;
  href: UrlObject;
  icon: IconType;
  action?: () => void;
}

const NavbarUpper = () => {
  const [expiresIn, setExpiresIn] = useState(0);
  const [loadHeavyContent] = useAtom(LoadHeavyContentAtom);
  const { userData } = useProfile();
  const { logout } = useLoginHook();
  const expiredIn = useReadLocalStorage<number>('expires_in');
  const [, handleNeufClick] = useElasticSearchKeyCategories();
  const windowSize = useWindowSize();
  const router = useRouter();
  const headlessParam = router.query.headless;
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const arrowUpContainerRef = useRef<HTMLDivElement>(null);

  // Define refs before use
  const searchFocused = useRef(false);
  const searchHasValue = useRef(false);

  const setArrowUpWidth = () => {
    if (arrowUpContainerRef.current)
      arrowUpContainerRef.current.style.width =
        searchFocused.current && searchHasValue.current ? '0px' : '1px';
  };

  const SCROLL_SEARCH_MAX = 100;
  const SCROLL_SEARCH_MIN = 0;

  const setSearchFocused: Dispatch<SetStateAction<boolean>> = (v) => {
    searchFocused.current = v as boolean;
    setArrowUpWidth();
  };

  const setSearchHasValue: Dispatch<SetStateAction<boolean>> = (v) => {
    searchHasValue.current = v as boolean;
    setArrowUpWidth();
  };

  const scrollSearchPosition = useRef(
    searchFocused.current ? SCROLL_SEARCH_MAX : SCROLL_SEARCH_MIN
  );
  const scrollPosition = useRef(
    typeof window !== 'undefined' ? window.scrollY : 0
  );

  const setSearchScrollPos = () => {
    if (searchContainerRef.current)
      searchContainerRef.current.style.height = `${
        (60 * scrollSearchPosition.current) / 100
      }px`;
  };

  useEffect(() => {
    const handleScroll = () => {
      const travel = -(scrollPosition.current - window.scrollY);
      scrollPosition.current = window.scrollY;

      scrollSearchPosition.current -=
        window.scrollY < 100 ? Math.abs(travel) : travel;
      if (
        scrollSearchPosition.current > SCROLL_SEARCH_MAX ||
        searchFocused.current
      )
        scrollSearchPosition.current = SCROLL_SEARCH_MAX;
      else if (scrollSearchPosition.current < SCROLL_SEARCH_MIN)
        scrollSearchPosition.current = SCROLL_SEARCH_MIN;

      setSearchScrollPos();
    };

    if (window !== undefined) {
      setSearchScrollPos();
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (window !== undefined) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  // fix hydration error
  useEffect(() => {
    if (headlessParam === 'true') {
      if (window !== undefined) {
        localStorage.setItem('headless', 'true');
      }
    }
    setExpiresIn(parseInt(`${expiredIn}`, 10));
  }, [expiredIn, headlessParam]);

  const isImmoNeuf =
    router.pathname
      .split('/')
      .some((segment) => segment.toLowerCase() === 'immoneuf') ||
    router?.query?.category === 'ImmoNeuf' ||
    router?.query.slug?.includes('Immobilier Neuf');

  const {
    value: isVisible,
    setFalse: close,
    setTrue: open,
  } = useBoolean(false);

  const isNeuf =
    router.pathname
      .split('/')
      .some((segment) => segment.toLowerCase() === 'immoneuf') ||
    router?.query?.category === 'ImmoNeuf';

  const isAuthenticated = useMemo(
    () => Date.now() / 1000 < +(expiresIn || 0),
    [expiresIn]
  );

  const { query } = router; // Get query params from the router

  function checkos() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return 'web';
  }

  const track = (event: string) => {
    // @ts-ignore
    if (window.rudderanalytics?.track) {
      window.rudderanalytics.track(event, {
        platform: checkos(),
      });
    }
  };

  function SavedSearchHandler() {
    if (Date.now() / 1000 > +(+(expiredIn || 0))) {
      router.push({
        pathname: '/login',
        query: {
          redirect: '/profile/?t=myAlerts',
        },
      });
    }
  }

  const profileSubMenu: IprofileSubMenu[] = flatMap([
    userData.userType === UserType.PRO
      ? {
          name: 'Tableau de bord',
          label: 'dashboard',
          href: { pathname: '/profile/', query: { t: 'dashboard' } },
          icon: RiDashboard2Line,
          action: close,
        }
      : [],
    {
      name: 'Mes annonces',
      label: 'myListings',
      href: { pathname: '/profile/', query: { t: 'myListings' } },
      icon: HiOutlineCollection,
      action: close,
    },
    {
      name: 'Mes alertes',
      label: 'myAlerts',
      href: { pathname: '/profile/', query: { t: 'myAlerts' } },
      icon: MdOutlineNotificationsActive,
      action: close,
    },
    {
      name: 'Mes transactions',
      label: 'transactions',
      href: { pathname: '/profile/', query: { t: 'transactions' } },
      icon: TkTokenHistory,
      action: close,
    },
    {
      name: 'Mes informations',
      label: 'details',
      href: { pathname: '/profile/', query: { t: 'details' } },
      icon: HiOutlineUserCircle,
      action: close,
    },
    {
      name: 'Sécurité',
      label: 'security',
      href: { pathname: '/profile/', query: { t: 'security' } },
      icon: HiOutlineShieldCheck,
      action: close,
    },
  ]);

  const ProSubMenu: IprofileSubMenu[] = flatMap([
    {
      name: 'Nos boutiques',
      label: 'Les boutiques sur tayara.tn',
      href: { pathname: '/shops' },
      icon: BiStore,
      action: close,
    },
    {
      name: 'Produits neufs',
      label: 'Les produits neufs sur tayara.tn',
      href: { pathname: '/search', query: { productType: 'Neuf' } },
      icon: HiOutlineSparkles,
      action: close,
    },
  ]);

  const helpSubMenu: IprofileSubMenu[] = flatMap([
    {
      name: `Besoin d${"'"}aide`,
      label: 'Contact de tayara.tn',
      href: { pathname: '/contact' },
      icon: IoHelpCircleOutline,
      action: close,
    },

    {
      name: 'Offres et services',
      label: 'Les offres et services de tayara.tn',
      href: { pathname: '/services' },
      icon: IoPricetagOutline,
      action: close,
    },

    {
      name: 'ImmoNeuf',
      label: '',
      href: { pathname: '/immoNeuf' },
      icon: AiOutlineHome,
      action: close,
    },
  ]);

  return (
    <>
      <div className="md:hidden flex justify-center bg-white">
        <>
          <div className="flex justify-center items-center relative h-[100px] w-[280px]  mx-auto rounded-2xl overflow-hidden">
            <div className="flex flex-col justify-center items-center w-full h-full">
              <span className=" text-2xl md:text-4xl font-extrabold text-neutral-200">
                Publicité
              </span>
              <img
                className={`w-[50px] md:w-[80px] relative -right-6 md:-right-8 transition-all duration-300 `}
                src={
                  isNeuf ? '/media/immoxxLogo.png' : '/media/tayara-logo.svg'
                }
                alt="Logo de tayara.tn"
              />
            </div>
          </div>
        </>
        {loadHeavyContent ? <MobileStickyBanner /> : <></>}
      </div>
      <div className={`w-full ${isImmoNeuf ? 'bg-neuf-100' : 'bg-primary'}`}>
        <div className="max-w-[1920px] flex justify-between h-[52px] md:h-[45px]  px-4 md:px-16 mx-auto items-center gap-x-2">
          <div className="flex w-fit items-center gap-x-2">
            <Link href="/" passHref>
              <img
                className="min-w-fit max-w-[120px] transition-all duration-300"
                src={
                  isImmoNeuf
                    ? '/media/immoExpertLogo.webp'
                    : '/media/t-tayara.svg'
                }
                alt="Logo de tayara.tn"
              />
            </Link>
            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-4 !mt-[3px] !mx-1 text-white hidden md:block"
            />

            <NavbarMenu />
            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-4 !mt-[3px] !mx-1 text-white hidden md:block"
            />
            <Text
              className="text-center text-white text-xs italic font-semibold hidden md:block"
              text={`Contactez-nous : ${
                isImmoNeuf ? '+216 93 941 034' : '+216 95 256 096'
              }  `}
            />
          </div>

          <div className="w-full flex items-center justify-between gap-x-1 md:hidden">
            <Divider
              orientation={Orientation.VERTICAL}
              className={'!h-3 !mx-1'}
            />
            <SearchInput
              filter
              className=" drop-shadow-sm grow w-full transition-[width] border-[1px] !bg-white border-neutral-300 !rounded-3xl focus:!bg-neutral-600 focus:placeholder:text-white "
              iconClasses={'text-white'}
              setSearchFocused={setSearchFocused}
              setSearchHasValue={setSearchHasValue}
            />
            <Divider
              orientation={Orientation.VERTICAL}
              className={'!h-3 !mx-1'}
            />
            {/* Mobile Menu Toggle Button */}
            <CgMenu
              onClick={open}
              size={26}
              className=" text-white cursor-pointer"
            />
          </div>
          {/* Mobile Menu */}
          {isVisible && (
            <div className="z-[9999] fixed !h-full inset-0 overflow-y-auto">
              <div className="w-full !h-full bg-white overflow-hidden transform transition-all">
                <div className="bg-white">
                  <div className="w-full py-4 flex items-center justify-between border-b-[1px] border-neutral-200/70">
                    <Link href="/" passHref>
                      <img
                        className="w-[100px] transition-all duration-300 ml-6"
                        src={
                          isNeuf
                            ? '/media/immoExpertLogo.webp'
                            : '/media/tayara-logo.svg'
                        }
                        alt="Logo de tayara.tn"
                      />
                    </Link>
                    <button
                      className="mr-4 p-2 border border-gray-400 hover:border-gray-600 hover:bg-gray-100 rounded-md h-auto"
                      onClick={close}
                    >
                      <CgClose size={20} className="text-neutral-500" />
                    </button>
                  </div>

                  <div className="w-full h-[85vh] overflow-y-scroll flex flex-col">
                    {isAuthenticated ? (
                      <Link
                        href="/"
                        onClick={() => {
                          logout();
                          close();
                        }}
                        replace
                        passHref
                      >
                        <button
                          aria-label="Se déconnecter"
                          className="w-[90vw] flex items-center justify-center gap-x-2 py-2 px-2 mx-auto my-4 rounded-lg border-[1px] border-primary bg-white hover:bg-primary text-primary hover:text-white text-sm font-bold font-arabic hover:!border-solid hover:!border-neutral-400"
                        >
                          <IoLogOut size={24} className="my-1" />
                          <h3 className="py-1 lg:text-2xs md:text-2xs text-sm font-arabic font-bold md:block xl:block w-max">
                            Se déconnecter
                          </h3>
                        </button>
                      </Link>
                    ) : (
                      <Link href="/login" onClick={close} replace passHref>
                        <button
                          aria-label="Se connecter"
                          className="w-[90vw] flex items-center justify-center gap-x-2 py-2 px-2 mx-auto my-4 rounded-lg border-[1px] border-neutral-500 bg-transparent text-neutral-500 hover:text-white hover:bg-neutral-500 text-sm font-bold font-arabic hover:!border-solid hover:!border-neutral-400"
                        >
                          <HiOutlineUserCircle size={24} className="my-1" />
                          <h3 className="py-1 lg:text-2xs md:text-2xs text-sm font-arabic font-bold md:block xl:block w-max">
                            Se connecter
                          </h3>
                        </button>
                      </Link>
                    )}

                    {/* Profile sub-menu */}
                    <div className="flex flex-col">
                      {isAuthenticated &&
                        profileSubMenu.map((item) => (
                          <Link
                            key={item.name}
                            href={item.href}
                            passHref
                            className="font-bold text-neutral-600 active:text-neutral-800 group pl-8 active:pl-10 mr-14 py-1 flex items-center justify-start rounded-e-3xl active:bg-neutral-100 text-sm"
                            aria-current={
                              item.label === query.t ? 'page' : undefined
                            }
                            onClick={() => {
                              if (item.name === 'Mes alertes') {
                                SavedSearchHandler();
                                track('Visited saved search');
                              }
                              if (item.action) {
                                item.action();
                              }
                            }}
                          >
                            <item.icon
                              className="flex-shrink-0 -ml-1 mr-3 h-10 w-6"
                              aria-hidden="true"
                            />
                            <span className="truncate">{item.name}</span>
                          </Link>
                        ))}
                      {isAuthenticated && (
                        <Divider
                          orientation={Orientation.HORIZONTAL}
                          className="!mx-auto !w-[85vw] !my-2"
                        />
                      )}
                    </div>

                    {/* Pro sub-menu */}
                    <div className="flex flex-col">
                      {ProSubMenu.map((item) => (
                        <Link
                          key={item.name}
                          href={item.href}
                          passHref
                          className="font-bold text-neutral-600 active:text-neutral-800 group pl-8 active:pl-10 mr-14 py-1 flex items-center justify-start rounded-e-3xl active:bg-neutral-100 text-sm"
                          aria-current={
                            item.label === query.t ? 'page' : undefined
                          }
                          onClick={() => {
                            if (item.name === 'Produits neufs') {
                              handleNeufClick();
                            }
                            if (item.action) {
                              item.action();
                            }
                          }}
                        >
                          <item.icon
                            className="flex-shrink-0 -ml-1 mr-3 h-10 w-6"
                            aria-hidden="true"
                          />
                          <span className="truncate">{item.name}</span>
                        </Link>
                      ))}
                      <Link
                        href="/immoNeuf"
                        passHref
                        className="font-bold text-neutral-600 active:text-neutral-800 group pl-8 active:pl-10 mr-14 py-1 flex items-center justify-start rounded-e-3xl active:bg-neutral-100 text-sm"
                        aria-current={undefined}
                        onClick={close}
                      >
                        <MdOutlineRealEstateAgent
                          className="flex-shrink-0 -ml-1 mr-3 h-10 w-6"
                          aria-hidden="true"
                        />
                        <span className="truncate">Immobiliers neufs</span>
                      </Link>
                      <Divider
                        orientation={Orientation.HORIZONTAL}
                        className="!mx-auto !w-[85vw] !my-2"
                      />
                    </div>

                    {/* Help sub-menu */}
                    <div className="flex flex-col mb-10">
                      {helpSubMenu.map((item) => (
                        <Link
                          key={item.name}
                          href={item.href}
                          passHref
                          className="font-bold text-neutral-600 active:text-neutral-800 group pl-8 active:pl-10 mr-14 py-1 flex items-center justify-start rounded-e-3xl active:bg-neutral-100 text-sm"
                          aria-current={
                            item.label === query.t ? 'page' : undefined
                          }
                          onClick={close}
                        >
                          <item.icon
                            className="flex-shrink-0 -ml-1 mr-3 h-10 w-6 text-neutral-800"
                            aria-hidden="true"
                          />
                          <span className="truncate">{item.name}</span>
                        </Link>
                      ))}
                    </div>

                    <div className="absolute bottom-0 h-16 bg-white flex justify-center items-center w-full">
                      <Link href="/terms" onClick={close} passHref>
                        <Text
                          className="text-3xs underline text-neutral-500"
                          text={`Conditions d'utilisation`}
                        />
                      </Link>
                      <Link href="/" onClick={close} passHref>
                        <Text
                          className="text-3xs underline text-neutral-500 mx-6"
                          text="Accueil"
                        />
                      </Link>
                      <Link href="/terms" onClick={close} passHref>
                        <Text
                          className="text-3xs underline text-neutral-500"
                          text="Politiques relatives aux cookies"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Desktop Links */}
          <div className="hidden md:flex mr-2 w-fit gap-x-3 items-center">
            <div className="flex items-center justify-center">
              <Text
                className="text-center text-white text-base mr-2 font-semibold italic"
                text="Explorer"
              />
              <Divider
                orientation={Orientation.VERTICAL}
                className="!h-[45px] rotate-12 text-white"
              />
            </div>
            <Link
              passHref
              href="/shops"
              aria-label="Les boutiques sur tayara.tn"
              className="w-fit flex items-center gap-x-2"
            >
              <BiStore size={19} className="text-white" />
              <Text
                className={`text-white text-xs italic font-semibold ${
                  windowSize.width < 1380 ? 'hidden' : 'block'
                }`}
                text="Nos boutiques"
              />
            </Link>

            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-4 !mt-[3px] !mx-1 text-white"
            />

            <Link
              passHref
              href={{ pathname: '/search', query: { productType: 'Neuf' } }}
              onClick={handleNeufClick}
              aria-label="Les produits neuf sur tayara.tn"
              className="w-fit flex items-center gap-x-2"
            >
              <HiSparkles size={19} className="text-white" />
              <Text
                className={`text-white text-xs italic font-semibold ${
                  windowSize.width < 1380 ? 'hidden' : 'block'
                }`}
                text="Produits neufs"
              />
            </Link>

            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-4 !mt-[3px] !mx-1 text-white"
            />

            <Link
              passHref
              href="/immoNeuf"
              onClick={handleNeufClick}
              aria-label="Immoexpert by Tayara"
              className="w-fit flex items-center gap-x-2"
            >
              <img
                className="min-w-fit max-w-[120px] transition-all duration-300"
                src="/media/immoExpertLogo.webp"
                alt="Logo de Immo-Expert by Tayara"
              />
              <Text
                className={`text-white text-xs italic font-semibold ${
                  windowSize.width < 1380 ? 'hidden' : 'block'
                }`}
                text="Nos projets immoNeuf"
              />
            </Link>

            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-4 !mt-[3px] !mx-1 text-white"
            />

            <Link
              passHref
              href="/services"
              aria-label="Les offres et services de tayara.tn"
              className="w-fit flex items-center gap-x-2"
            >
              <IoPricetag size={19} className="text-white" />
              <Text
                className={`text-white text-xs italic font-semibold ${
                  windowSize.width < 1380 ? 'hidden' : 'block'
                }`}
                text="Offres et services"
              />
            </Link>

            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-4 !mt-[3px] !mx-1 text-white"
            />

            <Link
              passHref
              href="/bons-plans-tayara"
              aria-label="Les Bons Plans de tayara.tn"
              className="w-fit flex items-center gap-x-2 neons"
            >
              <FaPercentage size={19} className="text-white" />
              <div
                className={`text-white text-sm italic font-semibold ${
                  windowSize.width < 1380 ? 'hidden' : 'block'
                }`}
                data-text="Bons Plans"
              >
                Bons Plans
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarUpper;
